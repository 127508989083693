@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  /*text-align: center;*/
  color: rgba(255,255,255,0.85);
}

body {
  margin: 0;
}

html {
  /*background-color: #FCF5E5; light theme */
  background-color: #121212;
}

.filters {
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  place-items: center;
}

.filters input {
  display: block;
  margin: 0 auto;
  width: 85%;
}

.searchBar {
  width: 1110px;
  margin: 0 30px;
  justify-content: center;
  height: 36px;
  font-size: 20px;
  padding-left: 8px;
}

table {
  width: 1200px;
  justify-content: center;
  margin: 0 auto;
  border-spacing: 0;
  padding: 20px;
  table-layout: fixed;
}



@media only screen and (max-width: 1200px) {
  table {
    max-width: 1200px;
    width: auto;
  }

}

.spellInfo {
  table-layout: fixed;
}

.spellHeader {
  /*box-shadow: inset 0 0 8px 0 grey; light theme */
  border-radius: 2px;
  /*background-color: white; light theme*/
  background-color: rgba(255,255,255,0.08);
  cursor: pointer;
  padding: 0 12px;
}

.openedFriend.common, .spellHeaderM.common:hover {
  background-color: rgba(255,255,255,0.12);
}

.openedFriend.uncommon, .spellHeaderM.uncommon:hover {
  background-color: rgba(255,255,0,0.12);
}

.openedFriend.rare, .spellHeaderM.rare:hover {
  background-color: rgba(0,0,255,0.12);
}

.openedFriend.restricted, .spellHeaderM.restricted:hover {
  background-color: rgba(255,0,0,0.12);
}

.openedFriend.artifact, .spellHeaderM.artifact:hover {
  background-color: rgba(0, 255, 255, 0.12);
}

/*.spellHeader td {
  border-bottom: 2px solid #898989;
  border-top: 2px solid #898989;
}
.spellHeader td:first-child {
  border-left: 2px solid #898989;
}

.spellHeader td:last-child {
  border-right: 2px solid #898989;
}*/

tr:first {
  padding-bottom: 2px;
}




.spellDetailsTop ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.spellDetailsTop ul li {
  break-inside: avoid-column;
}

.spellDetailsTop p {
  margin: 0;
}

.spellDetailsTop  {
  -webkit-column-count: 3;
    -webkit-column-gap: 10px;
    -moz-column-count: 3;
    -moz-column-gap: 10px;
  column-count: 3;
  column-gap: 10px;
}

@media only screen and (max-width: 800px) {
  .spellDetailsTop {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .headerBar h3 {
    display: none;
  }

  .accountDetails {
    display: none;
  }

}

.strong {
  font-weight: bold;
  font-size: 1.05em;
}

.spellInfoDiv {
  flex-direction: column;
  display: flex;
  
  border-radius: 2px;
}

.dividerLine {
  height: 2px;
  background-color: #bb86FC;
  margin: auto 30px !important;
}



.spell-name {
  text-align:left;
  padding-left: 10px;
}

.name-span {
  font-weight: bold;
  font-size: 1.2em;
  padding-top:4px;
}

.name-data {
  color: rgba(255,255,255,0.5);
  font-size: 0.85em;
  padding-bottom:4px;
}

.closed {
  display: none;
}



.concentration-icon {
  
  display: inline-block;
  font-size: 0.1em;
  height: 20px;
  width: 20px;
  background-image: url("./assets/concentration.png");
  background-size: contain;
}

.ritual-icon {
  
  display: inline-block;
  font-size: 0.1em;
  height: 20px;
  width: 20px;
  background-image: url("./assets/tome.png");
  background-size: contain;
}

.break {
  height: 12px;
}

.spellInfoDiv div {
  margin: 6px 6px;
}

.spellInfoDiv div:first-child {
  margin-top: 12px;
}

.spellInfoDiv div:last-child {
  margin-bottom: 12px;
}

.spellMeat p {
  margin: 12px;
}

.spellDetailsTop ul li {
  text-align: center;
}

.spellInfoDiv {
  background-color: rgba(255,255,255,0.07);
}



.schoolIcon {
  height: 36px;
  width: 36px;
  background-size: contain;
  margin: 10px;
  text-align: center;
  display: block;
  border-radius: 12px;
}

.abjuration-icon {
  background-image: url("./assets/abjuration.png");
}

.illusion-icon {
  background-image: url("./assets/illusion.png");
}

.evocation-icon {
  background-image: url("./assets/evocation.png");
}

.necromancy-icon {
  background-image: url("./assets/necromancy.png");
}

.enchantment-icon {
  background-image: url("./assets/enchantment.png");
}

.conjuration-icon {
  background-image: url("./assets/conjuration.png");
}

.transmutation-icon {
  background-image: url("./assets/transmutation.png");
}

.divination-icon {
  background-image: url("./assets/divination.png");
}

.animancy-icon {
  background-image: url("./assets/animancy.png");
}

.klaprymancy-icon {
  background-image: url("./assets/klaprymancy.png");
}

body {
  font-family: 'Roboto', sans-serif;
}

.spell-level, .name-data {
  font-weight: 400;
  font-size: 0.9em;
}

th {
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  padding-bottom: 4px;
}

td {
  font-size: 16px;
  padding: 0;
}



.tag-type {
  background-color: rgba(255,255,255,0.15);
  text-align: center;
  margin: 4px;
  padding: 6px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  box-shadow: inset 0 0 4px 0 white;
}

.taglist {
  padding-bottom: 20px;
  padding-left: 12px;
}

.username_banner {
  float: right;
}

.spellbook-token {
  height: 32px;
  font-size: 2em;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
}




.in-collection-icon:after {
  content: '–'
}

.not-in-collection-icon:after {
  content: '+'
}

.spellbook-token:hover {
  color: red;
  cursor: alias;
}

.in-collection {
  font-size: 0.9em;
  color: rgba(255,255,255,0.6);
}


.filterByCollection {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.collectionsDivR1 select {
  height: 32px;
  min-width: 300px;
  font-size: 18px;
  padding-left: 4px;
}

.collectionsDivR1 label {
  padding-right: 12px;
}

.collectionsDivR1 {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.collectionsDivLabels {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.collectionsDivLabels > h3 {
  margin: 0;
  font-size: 1.3em;
}

.background-color-selector ul {
  list-style-type: none;
  display: flex;
  padding-top: 22px;
  font-size: 0.7em;
}

.background-color-selector ul li {
  float: left;
  padding-right: 12px;
}

.headerBar {
  background-color: #242424;
  margin-bottom: 16px;
  border-bottom: 2px black solid;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10%;
}



.headerBar h3 {
  margin: 0;
  padding-right: 20px;
  padding-top: 4px;
}

.accountDetails {
  position: absolute;

  right: 10px;
}

.headerBar li, .headerBar a {
  font-weight: 500;
  font-size: 16px;
  color: rgba(255,255,255,0.85);
  text-decoration: none;
  padding: 6px;
}

.headerBar a:hover {
  background-color: rgba(255,255,255,0.08);
}

input, select {
  background-color: rgba(255,255,255,0.00);
  border: 0;
  color: white;
}

option {
  background-color: #121212;
}

button {
  background-color: rgba(255,255,255,0.05) !important;
  border: 0;
  margin: 2px;
  color: white !important;
}

button:hover {
  background-color: rgba(255,255,255,0.12) !important;
}

.login-details div{
  background-color: rgba(255,255,255,0) !important;
}

.filters {
  margin-bottom: 4px;
}


.displayModeNav {
  list-style: none;
}

.displayModeNav li {
  display: inline-block;
  padding: 0 20px;
}

.displayModeNav li a {
  transition: all 0.3s ease 0s;
}

.displayModeNav li a:hover {
  color: green;
}

a {
  font-size: inherit;
  
}

.filterDesc {
  margin: 0 auto;
  display: flex;
  align-self: center;
  max-width: 1200px;
  flex-direction: column;
}

.filterDesc button {
  width: 80%;
  align-self: center;
  font-size: 16px;
  color: rgba(255,255,255,0.12) !important;
}

.hidden {
  display:none;
}

.desc {
  height: 450px;
 
  width: 100%;
  
  align-self: center;
}

.descContent {
  padding-left: 16px;
}

.descContent ul {
  list-style-type: none;
  font-size: 0.7em;
  display: flex;

  flex-wrap: wrap;
  
}

.descContent ul li {
  padding-bottom: 12px;
  max-width: 50%;
  flex: 50%;
}

.descContent .descTerm {
  background-color: rgba(255,255,255,0.05);
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 1.05em;
}

.descContent .descEX {
  font-style: italic;
  margin-left: 4px;
}

.uncommon {
  background-color: rgba(255,255,0,0.05);
}

.rare {
  background-color: rgba(0,0,255,0.05);

}

.restricted {
  background-color: rgba(255,0,0,0.05);
}

.artifact {
  background-color: rgba(0, 255, 255, 0.05);
}

.weavebreaking {
  color: lightsteelblue;
}

.verendibel {
  color: rgb(245, 237, 11)
}

.base {
  border-left: 2px solid rgba(255,255,255,0.5);
}

.custom {
  border-left: 2px solid rgba(255,0,0,0.5);
}

.modified {
  border-left: 4px double white;
}


.blur {
  filter: blur(2px);
}

.namesake {
  font-style: italic;
  font-size: 0.92rem;
  font-weight: 500;
  color: rgb(181, 236, 181)
}

.spellOptions {
  display: flex;
  align-items: center;
  justify-content: right;
  
}

.highlightTab::after {
  content:"\00a0";
  height: 56px;
  width: 6px;
  background-color: rgba(255, 217, 0, 0.45);
  
}

.nohighlightTab::after {
  content:"\00a0";
  height: 56px;
  width: 6px;
  
}


.dotText {
  user-select: none;
  
}

.minimizedDotText {
  padding-right: 22px;
  font-weight: bolder;
}

.regularDotText {
  text-orientation:mixed;
  writing-mode: vertical-lr;
  font-size: 2em;
  padding-top: 6px;
}

.dotText:hover {
  color: grey;
  cursor: pointer;
}

.tooltipContainer {
  display: flex;
  
}

.TooltipIcon {
  padding: 4px;

}

.TooltipIcon:last-child {
  padding-right: 10px;
}

.TooltipIcon img {
  height: 20px;
}

.nothighlighted {
  visibility: hidden;
}


.MinimizedName {
  padding-left: 12px;
  font-size: 0.70em;
}

